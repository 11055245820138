import { Box, Container, Link, Typography, Fade, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IMAGES, STORE_BADGES } from "@APP/assets";
import CONFIG from "@APP/config";

const HomeScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Fade in timeout={700}>
      <Container component="main" maxWidth="sm">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          flexDirection="column"
          height="100vh"
          py="10vh"
          color={theme.palette.getContrastText(theme.palette.background.default)}>
          <img src={IMAGES.APP_LOGO} width={220} alt={t("ProductName")} />
          <Typography component="h1" className="hidden">
            {t("ProductName")}
          </Typography>
          {CONFIG.ASSOCIATION.CO_OPERATIVE_BANK && (
            <img src={IMAGES.ASSOCIATION_LOGO_LIGHT} alt="The Co-operative Bank" />
          )}
          {(CONFIG.URLS.APP_STORE_URL || CONFIG.URLS.PLAY_STORE_URL) && (
            <Typography component="p" variant="subtitle1" align="center">
              If you would like to be paid as easily please download our app and join our community
            </Typography>
          )}
          <Box my={2} textAlign="center">
            {CONFIG.URLS.OCM.REGISTRATION_URL && (
              <Typography variant="body1">
                Click{" "}
                <Link
                  href={CONFIG.URLS.OCM.REGISTRATION_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always"
                  color="inherit">
                  here
                </Link>{" "}
                to register.
              </Typography>
            )}
          </Box>
          {(CONFIG.URLS.APP_STORE_URL || CONFIG.URLS.PLAY_STORE_URL) && (
            <Box display="flex" alignItems="center">
              {CONFIG.URLS.APP_STORE_URL && (
                <Link href={CONFIG.URLS.APP_STORE_URL} target="_blank" rel="noopener noreferrer">
                  <img src={STORE_BADGES.APPLE_STORE} alt="Apple Store Icon" />
                </Link>
              )}
              {CONFIG.URLS.PLAY_STORE_URL && (
                <Link
                  href={CONFIG.URLS.PLAY_STORE_URL}
                  style={{ width: 154 }}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={STORE_BADGES.GOOGLE_PLAY_STORE} alt="Google Play Store Icon" />
                </Link>
              )}
            </Box>
          )}
          {CONFIG.URLS.TERMS_AND_CONDITIONS_URL ? (
            <Link
              href={CONFIG.URLS.TERMS_AND_CONDITIONS_URL}
              variant="caption"
              color="inherit"
              underline="always">
              Terms and Conditions
            </Link>
          ) : null}
        </Box>
      </Container>
    </Fade>
  );
};

export default HomeScreen;
