import React from "react";
import { Box, Button, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import DownloadIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { format } from "date-fns";

import { ERP_ID, ATTACHEMENTS, StandingOrderDetails } from "@APP/types";
import { AppState } from "@APP/redux";
import { formatCurrency } from "@APP/utils";

interface Props {
  rtpData: AppState["rtp"]["rtpData"];
  senderData: AppState["rtp"]["senderData"];
  standingOrderDetails?: StandingOrderDetails;
}

const RTPSummary: React.FC<Props> = ({ rtpData, senderData, standingOrderDetails }) => {
  const { entityDetails, lineItems, reference } = rtpData?.payable || {};

  const isInternal = entityDetails?.source === ERP_ID.INTERNAL;
  const invoiceLink = rtpData?.payable?.attachments.find(
    (item) => item.name === ATTACHEMENTS.RECEIVABLE_PDF && item.contentType === "application/pdf",
  )?.uri;

  return (
    <Box textAlign="center">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography>
          <b>{senderData?.businessContact?.name ?? senderData?.name}</b> has requested
        </Typography>
      </Box>
      <Box my={0.5}>
        <Typography color="textSecondary" component="p" variant="h4">
          {formatCurrency(rtpData?.paymentDetails.amount)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography gutterBottom>{isInternal ? "for" : "for invoice"}&nbsp;</Typography>
        <Typography component="p" variant="subtitle1" gutterBottom>
          {isInternal ? lineItems?.[0].description : reference}
        </Typography>
      </Box>
      {standingOrderDetails && (
        <Box mt={2} mb={2} display="flex" flexDirection="column" alignItems="center">
          <Typography gutterBottom>Payment to be taken in the below instalments</Typography>
          <Box
            mt={1}
            maxWidth={320}
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom>
                Payment Amount
              </Typography>
              <Typography gutterBottom>
                {formatCurrency(standingOrderDetails.firstPaymentAmount)}
              </Typography>
            </Box>
            {standingOrderDetails.finalPaymentAmount && (
              <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" gutterBottom>
                  Final Payment
                </Typography>
                <Typography gutterBottom>
                  {formatCurrency(standingOrderDetails.finalPaymentAmount)}
                </Typography>
              </Box>
            )}
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom>
                Frequency
              </Typography>
              <Typography gutterBottom>{standingOrderDetails.frequency}</Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom>
                Occurrences
              </Typography>
              <Typography gutterBottom>{standingOrderDetails.numberOfPayments}</Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom>
                First payment date
              </Typography>
              <Typography gutterBottom>
                {format(new Date(standingOrderDetails.firstPaymentDate!), "yyyy-MM-dd")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
        <Button
          size="small"
          href={`mailto:${senderData?.businessContact?.email ?? senderData?.companyInfo.email}`}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<EmailIcon />}>
          Contact {senderData?.businessContact?.name ?? senderData?.name}
        </Button>
        {invoiceLink && (
          <Button
            size="small"
            href={invoiceLink}
            target="_blank"
            rel="noopener noreferrer"
            download
            startIcon={<DownloadIcon />}>
            Download Invoice
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RTPSummary;
