import { ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#371CA1",
    paper: "#FFFFFF",
  },
  primary: {
    main: "#000",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#ec111a",
    contrastText: "#ffffff",
  },
  error: {
    main: "#ec111a",
  },
  info: {
    main: "#371CA1",
  },
  action: {
    active: "#3ed490",
  },
  text: {
    primary: "#121212",
    secondary: "#121212",
  },
};

export default palette;
