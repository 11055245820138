import { ThemeOptions, SimplePaletteColorOptions } from "@mui/material";
import palette from "./palette";

// Due to issues with runtime type checking causing errors when using palette.primary.main / palette.secondary.main from MUI type, had to define again internally.
const palettePrimary = palette.primary as SimplePaletteColorOptions;

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: 18,
        minWidth: 140,
        borderRadius: 10,
      },
      contained: {
        padding: "0.75rem 1.75rem",
      },
      text: {
        fontSize: 13,
        color: palette.text?.primary,
      },
      textSecondary: {
        backgroundColor: "transparent",
        fontSize: 14,
        textDecoration: "underline",
        "&:hover": {
          backgroundColor: "transparent",
          textDecoration: "underline",
        },
      },
      sizeLarge: {
        fontSize: "1rem",
        padding: "0.875rem 2.5rem",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        fontSize: 14,
        backgroundColor: "#FFFFFF",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: "#2e2e6d80",
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&:focus-visible, &:focus-visible:hover": {
          outline: `2px solid ${palettePrimary.main}`,
          outlineOffset: "-2px",
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: "#5FA022",
      },
    },
  },
};

export default components;
