import React from "react";
import { CircularProgress, Fade } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import Header from "./Header";
import Footer from "./Footer";

interface Props {
  children: React.ReactElement;
  loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    position: "relative",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: "1.6rem",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  loaderContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    paddingTop: "20vh",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 100,
  },
}));

const PageLayout = ({ children, loading = false }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          {loading && (
            <div className={classes.loaderContainer}>
              <CircularProgress size="8vh" thickness={3} color="primary" />
            </div>
          )}
          <div className={classes.content}>
            <main className={classes.mainContent}>
              <Fade timeout={700} in={!loading}>
                {children}
              </Fade>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
