import { ComponentType } from "react";
import { RouteProps } from "react-router-dom";

import { SCREEN_PATHS } from "./paths";
import { providerProtector, RouteProtector } from "./protectors";

import {
  HomeScreen,
  LandingScreen,
  ConfirmEmail,
  ResetPasswordView,
  MaverickPaymentView,
} from "@APP/views";
import { PrivacyNoticeScreen, TermsAndConditionsScreen } from "@APP/hosted-views";
import DeclineRTPScreen from "@APP/views/DeclineRTPScreen";
import BankSelectScreen from "@APP/views/BankSelectScreen";
import InitiateRTPPaymentScreen from "@APP/views/InitiateRTPPaymentScreen";
import RTPCompletePaymentScreen from "@APP/views/RTPCompletePaymentScreen";
import CONFIG from "@APP/config";

export interface RouteConfig {
  /**
   * Route path.
   * Must be `absolute` for regular routes and `relative` for modal routes.
   */
  path?: string | string[];
  /**
   * Whether to render path matches the `location.pathname` exactly.
   */
  exact?: boolean;
  /**
   * Component to render.
   */
  component?: ComponentType<any>;
  /**
   * "RouteProtector" function(s) responsible for determining if a route is available or not.
   */
  protector?: RouteProtector | RouteProtector[];
  /**
   * JSX to render whether the path matches the location or not.
   */
  children?: RouteProps["children"];
  /**
   * A collection of child routes.
   * Being passed down to the route's `component` as a property.
   * Must be handled on a component level, e.g. by rendering an additional router.
   */
  routes?: RouteConfig[];
  /**
   * Location to redirect. The new location will override the current location in the history stack.
   */
  redirect?: string;
  /**
   * Modal routes are relative: they appear on top of their parent routes.
   * It means that the parent route can't have `{ exact: true }` in order to support relative paths.
   */
  modal?: boolean;
}

/**
 * Centralized route configuration.
 */
export const routes: RouteConfig[] = [
  {
    path: SCREEN_PATHS.CONFIRM_EMAIL,
    component: ConfirmEmail,
  },
  {
    path: SCREEN_PATHS.RESET_PASSWORD,
    component: ResetPasswordView,
  },
  {
    path: SCREEN_PATHS.LANDING,
    component: LandingScreen,
  },
  {
    path: SCREEN_PATHS.DECLINE_RTP,
    component: DeclineRTPScreen,
  },
  {
    path: SCREEN_PATHS.BANK_SELECT,
    component: BankSelectScreen,
    protector: providerProtector,
  },
  {
    path: SCREEN_PATHS.INITIANE_PAYMENT,
    component: InitiateRTPPaymentScreen,
    protector: providerProtector,
  },
  {
    path: SCREEN_PATHS.PAYMENT_COMPLETE,
    component: RTPCompletePaymentScreen,
  },
  {
    path: SCREEN_PATHS.MAVERICK_PAYMENT,
    component: MaverickPaymentView,
    protector: providerProtector,
  },
  CONFIG.HOSTED_PAGES?.TERMS_AND_CONDITIONS
    ? {
        path: SCREEN_PATHS.TERMS_AND_CONDITIONS,
        component: TermsAndConditionsScreen,
      }
    : {},
  CONFIG.HOSTED_PAGES?.PRIVACY_NOTICE
    ? {
        path: SCREEN_PATHS.PRIVACY_NOTICE,
        component: PrivacyNoticeScreen,
      }
    : {},
  {
    path: SCREEN_PATHS.DEFAULT,
    component: HomeScreen,
  },
];
