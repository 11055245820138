import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";

import { PageLayout, Message } from "@APP/components";
import { ICONS } from "@APP/assets";
import { API } from "@APP/services";
import CONFIG from "@APP/config";

interface RouteParams {
  token: string;
}
const ConfirmEmail: React.FC<RouteComponentProps> = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const params = match.params as RouteParams;
  const { token } = params;

  const searchParams = new URLSearchParams(document.location.search);

  const orgId = searchParams.get("orgId");

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const { email } = orgId
            ? await API.verifyBusinessContactEmail(token, orgId)
            : await API.verifyEmail(token);
          setEmail(email);
        } catch (error: any) {
          setErrorMessage(
            error?.response?.data?.errorMessage ??
              "Sorry, something went wrong when verifying your email. Please try again later.",
          );
        }
        setLoading(false);
      })();
    }
  }, [token]);

  const renderMainContent = () => {
    if (errorMessage) {
      return (
        <Message
          type="error"
          title="Oops, something seems to have gone wrong."
          description={errorMessage}
        />
      );
    }

    return (
      <Box display="flex" alignItems="center" flexDirection="column" ml={2} mr={2}>
        <img src={ICONS.CONFIRM_ICON} style={{ width: "6.5vh" }} alt="Success" />
        <Box mt="8vh">
          <Typography variant="h6">Thank you!</Typography>
        </Box>
        <Box my="6vh">
          <Typography color="textSecondary" variant="h5" component="span">
            {email}
          </Typography>
        </Box>
        <Typography align="center">Your e-mail address has been successfully verified.</Typography>
        <Box mt={8} width="100%">
          <Button
            size="large"
            component="a"
            variant="contained"
            fullWidth
            color="primary"
            href={CONFIG.URLS.OCM.ROOT_URL}>
            Go to {CONFIG.PRODUCT_NAME}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" flex={1} alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default ConfirmEmail;
