import React, { useEffect, useMemo, useState } from "react";
import { RouteProps } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import queryString from "query-string";
import { useFormik } from "formik";
import { isMobile } from "react-device-detect";

import CONFIG from "@APP/config";
import { PageLayout, Message } from "@APP/components";
import { API } from "@APP/services";
import { passwordValidationSchema } from "@APP/utils";
import { history } from "@APP/navigation";

import { validate } from "./resetPasswordSchema";
import { PasswordField } from "./PasswordField";

type FormInputData = { password: string };

const UNABLE_TO_REDIRECT = CONFIG.URLS.MOBILE?.LOGIN_URL && !isMobile;

const ResetPasswordView: React.FC<RouteProps> = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const queryParams = queryString.parse(location?.search ?? "");
  const { code } = queryParams;

  useEffect(() => {
    (async () => {
      if (!code) {
        setCodeInvalid(true);
        setLoading(false);
        return;
      }
      try {
        await API.verifyResetPasswordCode(code as string);
      } catch (err) {
        setCodeInvalid(true);
      }
      setLoading(false);
    })();
  }, [code]);

  const { errors, handleBlur, handleChange, handleSubmit, touched, isValid, values } = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: passwordValidationSchema,
    validate,
    onSubmit: async ({ password }: FormInputData) => {
      setLoading(true);
      setSubmitError("");
      try {
        await API.resetPassword(code as string, password);
        setFormSubmitted(true);
      } catch (err) {
        setSubmitError("Unable to set new password, please try again.");
      }
      setLoading(false);
    },
  });

  const finishButtonProps = useMemo(() => {
    return {
      text: UNABLE_TO_REDIRECT ? "Okay" : "Go to Login Page",
      onClick: UNABLE_TO_REDIRECT ? () => history.replace("/") : undefined,
      href: UNABLE_TO_REDIRECT
        ? undefined
        : CONFIG.URLS.MOBILE?.LOGIN_URL ?? CONFIG.URLS.OCM.LOGIN_URL,
    };
  }, []);

  const renderMainContent = () => {
    if (codeInvalid) {
      return (
        <Message
          type="error"
          title="Oops, something seems to have gone wrong"
          description="Your reset code is invalid or expired, please try again."
          buttons={[{ variant: "contained", color: "secondary", ...finishButtonProps }]}
        />
      );
    }

    if (formSubmitted) {
      return (
        <Message
          type="success"
          title="Password reset successful"
          description={
            !UNABLE_TO_REDIRECT
              ? `You can use your new password to log in to the${
                  CONFIG.URLS.MOBILE?.LOGIN_URL ? " mobile" : ""
                } app.`
              : ""
          }
          buttons={[{ variant: "contained", color: "primary", ...finishButtonProps }]}
        />
      );
    }

    return (
      <>
        <Typography style={{ marginBottom: "4vh" }} variant="h6" align="center">
          Type and confirm a secure new password for the account.
        </Typography>
        <Box component="form" onSubmit={handleSubmit as any} maxWidth={550} width="100%">
          <Box display="flex" alignItems="center">
            <Typography style={{ width: "40%" }}>Enter Password:</Typography>
            <PasswordField
              placeholder="Password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography style={{ width: "40%" }}>Confirm Password:</Typography>
            <PasswordField
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            />
          </Box>

          {(touched.password && errors.password) ||
          (touched.password && errors.confirmPassword) ||
          submitError ? (
            <Box mt={2}>
              <Typography color="error" variant="caption">
                {touched.password && errors.password}
                {touched.confirmPassword && errors.confirmPassword}
              </Typography>
              <Typography align="center" color="error">
                {submitError}
              </Typography>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="center" mt="4vh" mx="auto" width={240}>
            <Button
              size="large"
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || !touched.password || !touched.confirmPassword || loading}>
              Reset
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" flex={1} alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default ResetPasswordView;
