import CONFIG from "@APP/config";
import { BanksResponse } from "@APP/types";
import request from "../request";

/**
 * Temporary base route to enable proxy for API requests.
 * Addresses https://bankifi.atlassian.net/browse/BK-46.
 */
const TEMP_API_BASE = "/api";

export const getBanks = async () => {
  const res = await request<{ data: BanksResponse[] }>({
    method: "GET",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/custodians?${CONFIG.API.CUSTODIANS_PROVIDER_PATH}`,
    url: `${TEMP_API_BASE}/custodians?${CONFIG.API.CUSTODIANS_PROVIDER_PATH}`,
  });
  return res.data.data;
};
