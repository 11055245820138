import { useState } from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const PasswordField = (props: TextFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextField
      {...props}
      style={{ flexGrow: 1 }}
      type={passwordVisible ? "text" : "password"}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setPasswordVisible(!passwordVisible)}
              aria-label="toggle password visibility"
              edge="end"
              size="large">
              {passwordVisible ? (
                <VisibilityIcon color="primary" />
              ) : (
                <VisibilityOffIcon color="primary" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}></TextField>
  );
};
