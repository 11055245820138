import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";

import { ICONS } from "@APP/assets";
import { PageLayout, Message, RTPSummary } from "@APP/components";
import { useAppDispatch, useAppSelector, useThunkDispatch } from "@APP/redux";
import { /* loadAuthToken, */ loadBanks, clearRtpState, getRtpDetails } from "@APP/redux/actions";
import { PAYMENT_PROVIDER, RTPStatus } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import config from "@APP/config";
import { API } from "@APP/services";
import { useTranslation } from "react-i18next";
import PaymarkNotice from "./parts/PaymarkNotice";

interface QueryParams {
  senderDataKey: string;
  rtpKey: string;
}

const RTP_UNAVAILABLE_STATUSES = [
  RTPStatus.Cancelled,
  RTPStatus.Declined,
  RTPStatus.PaymentReceived,
  RTPStatus.PaymentSent,
];

const LandingScreen: React.FC<RouteComponentProps> = ({ match, history }) => {
  const { t } = useTranslation();
  const dispatchThunk = useThunkDispatch();
  const dispatch = useAppDispatch();

  const rtpState = useAppSelector((state) => state.rtp);
  const banksState = useAppSelector((state) => state.banks);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [payButtonLoading, setPayButtonLoading] = useState(false);

  const params = match.params as QueryParams;
  const { senderDataKey, rtpKey } = params;

  const lastSelectedBank = banksState.selectedBank;

  useEffect(() => {
    const processDataLoading = async () => {
      setLoading(true);
      if (!(senderDataKey && rtpKey)) {
        setErrorMessage("Sorry, we were unable to locate this request.");
        return;
      }
      try {
        /**
         * Disabled due to: https://bankifi.atlassian.net/browse/BK-46
         */
        // await dispatchThunk(loadAuthToken());
        if (config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MONEYHUB) {
          await dispatchThunk(loadBanks());
        }
        await dispatchThunk(getRtpDetails(senderDataKey, rtpKey));
      } catch (err) {
        setErrorMessage(
          "It looks like there is a problem, please try again in a few moments. If the problem continues please contact the person who sent you the request",
        );
      }
      setLoading(false);
    };
    processDataLoading();
  }, []);

  const handlePayClick = async () => {
    // -- Maverick integration --
    if (config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MAVERICK) {
      try {
        await API.initiateRTP(rtpState.rtpData?.paymentRequestId!);

        history.push(SCREEN_PATHS.MAVERICK_PAYMENT);
      } catch (error) {
        setErrorMessage(
          "Sorry, we are currently unable to take your payment, please try again later",
        );
      }

      return;
    }

    // -- Paymark integration --
    if (config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.PAYMARK) {
      try {
        setPayButtonLoading(true);
        const paymentData = await API.paymarkMakePayment(
          {
            organisationId: rtpState.senderData!.companyInfo.id,
            amount: rtpState.rtpData!.paymentDetails.amount,
            reference: rtpState.rtpData!.payable.reference,
          },
          {
            rtpId: rtpState.rtpData!.paymentRequestId,
            redirectUrl: window.location.origin + config.API.REDIRECT_PATH_PAYMENT_COMPLETE,
            erpId: rtpState.rtpData!.payable.entityDetails.source,
            invoiceId: rtpState.rtpData!.payable.entityDetails.externalId,
            initialInvoiceType: rtpState.rtpData!.paymentDetails.additionalData.initialInvoiceType!,
            customerId: rtpState.rtpData!.payable.supplierContact.email,
          },
        );
        await API.initiateRTP(rtpState.rtpData?.paymentRequestId!);
        window.location.href = paymentData?.redirectUrl;
      } catch (err) {
        console.log("Paymark Make Payment Error", err);
        setErrorMessage(
          "Sorry, we have been unable to reach your chosen bank, please try again in a few moments",
        );
        setPayButtonLoading(false);
      }
      return;
    }

    // -- Moneyhub integration --
    if (lastSelectedBank) {
      history.push(SCREEN_PATHS.INITIANE_PAYMENT.replace(":bankId", lastSelectedBank));
      return;
    }
    history.push(SCREEN_PATHS.BANK_SELECT);
  };

  const handleDeclineClick = () => {
    history.push(
      SCREEN_PATHS.DECLINE_RTP.replace(":requestId", rtpState.rtpData?.paymentRequestId ?? ""),
    );
  };

  const handleResetClick = () => {
    dispatch(clearRtpState());
    history.push("/");
  };

  const renderMainContent = () => {
    if (errorMessage) {
      return (
        <Message
          style={{ margin: "auto" }}
          type="error"
          title="Oops, something seems to have gone wrong"
          description={errorMessage}
          buttons={[{ variant: "contained", color: "secondary", onClick: handleResetClick }]}
        />
      );
    }

    if (rtpState.rtp?.status && RTP_UNAVAILABLE_STATUSES.includes(rtpState.rtp.status)) {
      return (
        <Message
          style={{ margin: "auto" }}
          type="info"
          title="Oops, something seems to have gone wrong"
          description={t("Screens.Landing.error1")}
          buttons={[{ variant: "contained", color: "primary", onClick: handleResetClick }]}
        />
      );
    }

    return (
      <>
        <img src={ICONS.REQUEST_ICON} style={{ width: "6.5vh" }} alt="Request to pay" />
        <Box mt="2.5vh" mb="3vh">
          <Typography align="center">You have been sent a payment request</Typography>
        </Box>
        <RTPSummary
          rtpData={rtpState.rtpData}
          senderData={rtpState.senderData}
          standingOrderDetails={rtpState.rtp?.standingOrder}
        />
        {rtpState.rtp?.status === RTPStatus.InProgress ? (
          <Box mt="2.5vh">
            <Message type="info" title="This payment is already in progress" showIcon={false} />
          </Box>
        ) : (
          <>
            {config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.PAYMARK ? (
              <Box mt="1vh">
                <PaymarkNotice />
              </Box>
            ) : null}
            <Typography variant="caption" align="center">
              {t("Screens.Landing.text1")}
            </Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="center" my="1vh" width="100%">
              <Box minWidth="200px" maxWidth="240px" m="2%">
                <Button
                  size="large"
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={payButtonLoading}
                  onClick={handlePayClick}>
                  {payButtonLoading ? <CircularProgress color="secondary" size={28} /> : "Pay"}
                </Button>
              </Box>
              <Box minWidth="200px" maxWidth="240px" m="2%">
                <Button
                  size="large"
                  disabled={payButtonLoading}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleDeclineClick}>
                  Decline
                </Button>
              </Box>
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" flex={1} alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default LandingScreen;
