import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { IMAGES } from "@APP/assets";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "15vh",
    maxWidth: 130,
    [theme.breakpoints.down("md")]: {
      width: "13vh",
    },
  },
}));

const PaymarkNotice = () => {
  const styles = useStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Typography variant="body2" align="center">
        Selecting Pay will redirect you to the secure Paymark Click Gateway
      </Typography>
      <img className={styles.logo} src={IMAGES.PAYMENT_PROVIDER_LOGO} alt="Paymark Logo" />
    </Box>
  );
};

export default PaymarkNotice;
