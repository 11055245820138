import CONFIG from "@APP/config";
import { AppState } from "@APP/redux";
import { PAYMENT_PROVIDER } from "@APP/types";

import { SCREEN_PATHS } from "./paths";

/**
 * "RouteProtector" is responsible for securing the access to the corresponding route.
 * Based on the current global application state (Redux state) and the path to the route,
 * RouteProtector allows to deny access to a route or perform a redirect
 * by returning either a `boolean` indicating the user access or a `string` containing the redirection route.
 * @param state - Global (Redux) aplication state.
 * @param path - Path to the given route.
 * @return boolean | string
 * 1. `false` if access is denied;
 * 2. `string` with "react-router" compatible redirect path to perform redirect.
 */
export type RouteProtector = (appState: AppState, path?: string | string[]) => boolean | string;

export const providerProtector: RouteProtector = (_, path) => {
  if (
    CONFIG.PAYMENT_PROVIDER === PAYMENT_PROVIDER.PAYMARK ||
    (CONFIG.PAYMENT_PROVIDER !== PAYMENT_PROVIDER.MAVERICK &&
      path === SCREEN_PATHS.MAVERICK_PAYMENT)
  ) {
    return "/";
  }

  return true;
};
