import formUrlEncoded from "form-urlencoded";
import CONFIG from "@APP/config";
import request from "../request";

export const verifyEmail = async (token: string) => {
  const res = await request<{ email: string; verified: boolean }>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/register`,
      data: formUrlEncoded({
        action: "verify_email",
        email: token,
      }),
    },
    {
      auth: false,
    },
  );
  return res.data;
};

export const resetPassword = async (code: string, password: string) => {
  const res = await request<any>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset/confirm`,
      params: { code },
      data: { password },
    },
    {
      auth: false,
    },
  );
  return res.data;
};

export const verifyResetPasswordCode = async (code: string) => {
  const res = await request<any>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset/check_code`,
      params: { code },
    },
    {
      auth: false,
    },
  );
  return res.data;
};
