import APP_LOGO from "./common/appLogo.svg";
import ASSOCIATION_LOGO_LIGHT from "./common/associationLogoLight.png";
import ASSOCIATION_LOGO_DARK from "./common/associationLogoDark.png";
import PAYMENT_PROVIDER_LOGO from "./common/paymentProviderLogo.png";

import APPLE_STORE from "./storeBadges/AppleBadge.svg";
import GOOGLE_PLAY_STORE from "./storeBadges/GooglePlayBadge.svg";

import REQUEST_ICON from "./icons/requestIcon.svg";
import CONFIRM_ICON from "./icons/confirmIcon.svg";
import RESET_PASSWORD_ICON from "./icons/resetPasswordIcon.svg";
import ERROR_ICON from "./icons/errorIcon.svg";
import DECLINE_ICON from "./icons/declineIcon.svg";
import BANK_ICON from "./icons/bankIcon.svg";

interface Assets {
  IMAGES: {
    APP_LOGO: string;
    PAYMENT_PROVIDER_LOGO: string;
    ASSOCIATION_LOGO_LIGHT: string;
    ASSOCIATION_LOGO_DARK: string;
  };
  STORE_BADGES: {
    APPLE_STORE: string;
    GOOGLE_PLAY_STORE: string;
  };
  ICONS: {
    REQUEST_ICON: string;
    CONFIRM_ICON: string;
    RESET_PASSWORD_ICON: string;
    ERROR_ICON: string;
    DECLINE_ICON: string;
    BANK_ICON: string;
  };
}

export const IMAGES: Assets["IMAGES"] = {
  APP_LOGO,
  ASSOCIATION_LOGO_LIGHT,
  ASSOCIATION_LOGO_DARK,
  PAYMENT_PROVIDER_LOGO,
};

export const STORE_BADGES: Assets["STORE_BADGES"] = {
  APPLE_STORE,
  GOOGLE_PLAY_STORE,
};

export const ICONS: Assets["ICONS"] = {
  REQUEST_ICON,
  CONFIRM_ICON,
  RESET_PASSWORD_ICON,
  ERROR_ICON,
  DECLINE_ICON,
  BANK_ICON,
};

export default {
  IMAGES,
  ICONS,
  STORE_BADGES,
} as Assets;
