import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material";
import { Router } from "react-router-dom";

import { AlertProvider } from "@APP/hooks/useAlert";
import { GlobalStyles, themeOptions } from "@APP/styles";
import { AppRouter, routes, history } from "@APP/navigation";

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme({ ...themeOptions })}>
        <GlobalStyles />
        <AlertProvider>
          <Router history={history}>
            <AppRouter routes={routes} />
          </Router>
        </AlertProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
