import request from "../request";
// import CONFIG from "@APP/config";
import { PaymarkPaymentPayload } from "@APP/types";

/**
 * Temporary base route to enable proxy for API requests.
 * Addresses https://bankifi.atlassian.net/browse/BK-46.
 */
const TEMP_API_BASE = "/api";

export const paymarkMakePayment = async (
  payload: PaymarkPaymentPayload,
  params: {
    rtpId: string;
    redirectUrl: string;
    erpId: string;
    invoiceId: string;
    initialInvoiceType: string;
    customerId: string;
  },
) => {
  const res = await request<{ redirectUrl: string; additionalData: any }>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/payment-gateway/paymark/payments`,
    url: `${TEMP_API_BASE}/payment-gateway/paymark/payments`,
    data: payload,
    params,
  });
  return res.data;
};
