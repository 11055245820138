import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AlertContext, ButtonTypes } from "./AlertProvider";

const useStyles = makeStyles((theme) => ({
  background: {
    backdropFilter: "blur(1px)",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  button: {
    minWidth: "200px",
    maxWidth: "240px",
    margin: "2%",
  },
}));

const AlertModal = () => {
  const styles = useStyles();

  const { alertOptions, closeAlert, showAlert } = React.useContext(AlertContext);
  const { title, message, buttons } = alertOptions ?? {};

  if (!showAlert) return null;

  const handleButtonPress = (buttonIndex: number) => {
    const buttonProps = buttons?.[buttonIndex];
    if (buttonProps && buttonProps.onClick) buttonProps.onClick(buttonProps.text);
    if (buttonProps?.closable) closeAlert();
  };

  return (
    <Dialog
      className={styles.background}
      open
      fullWidth
      maxWidth="md"
      PaperProps={{ elevation: 0 }}>
      {title && (
        <DialogTitle>
          <Typography align="center" variant="h4">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        {message?.component && message.component}
        {message?.value && (
          <DialogContentText align="center" color="textPrimary" variant="body1">
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className={styles.buttonsContainer}>
        {!!buttons?.length &&
          buttons.map(({ text, type }, index) => {
            if (type === ButtonTypes.back) {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  key={`${text}${index}`}
                  onClick={() => handleButtonPress(index)}>
                  {text ?? "Close"}
                </Button>
              );
            }

            if (type === ButtonTypes.decline) {
              return (
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.button}
                  key={`${text}${index}`}
                  onClick={() => handleButtonPress(index)}>
                  {text ?? "Close"}
                </Button>
              );
            }

            return (
              <Button
                className={styles.button}
                key={`${text}${index}`}
                onClick={() => handleButtonPress(index)}>
                {text ?? "Close"}
              </Button>
            );
          })}
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
