import React, { useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import queryString from "query-string";
import * as Sentry from "@sentry/react";

import { API } from "@APP/services";
import { PaymentStatus, PAYMENT_PROVIDER, RTPStatus } from "@APP/types";
import { ICONS } from "@APP/assets";
import { PageLayout, Message, MoneyhubLegalInfo } from "@APP/components";
import { history } from "@APP/navigation";
import { clearRtpState } from "@APP/redux/actions";
import { useAppDispatch, useAppSelector } from "@APP/redux";
import { formatCurrency } from "@APP/utils";
import config from "@APP/config";

const ERROR_MESSAGES = {
  CHECK_BANK:
    "Your payment has not completed successfully. Please check your paying bank account and try again in a few moments",
  ACCESS_DENIED:
    "We understand that sometimes you wish to change your mind about making payment. If you have any questions or are concerned about the payment request please contact the business that sent it directly",
  MAVERICK_ERROR:
    "We are unable to update the status of your payment. Please try again to update your payment status.",
};

const RTPCompletePaymentScreen: React.FC<RouteProps> = ({ location }) => {
  const dispatch = useAppDispatch();

  const rtpState = useAppSelector((state) => state.rtp);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [confirmationInfo, setConfirmationInfo] = useState<{
    sender: string;
    amount: { amount: string; currency: string };
    bank?: string;
    reference: string;
  }>();

  const queryParams = queryString.parse(location?.search ?? "");
  const {
    code,
    state,
    error,
    status,
    errorMessage: queryErrorMessage,
    rtpId,
    status: maverickPaymentStatus,
    id: maverickPaymentId,
  } = queryParams;

  const processMoneyhubPayment = async () => {
    try {
      //
      // STEP 1: get payment state with all the necessary data for confirmation.
      //
      const paymentState = await API.getPaymentState(state as string);
      const { consentId, meta } = paymentState;
      const custodianId = paymentState.context.custodianId;
      const { BankId, redirectUrl } = meta.reduce((prev, curr) => {
        if (curr.key && ["BankId", "requestId", "redirectUrl"].includes(curr.key)) {
          return { ...prev, [curr.key]: curr.value };
        }
        return prev;
      }, {} as { [key: string]: string });

      if (rtpState.rtp?.standingOrder) {
        //
        // STEP 2: Confirm payment.
        //
        const paymentConfirmResponse = await API.confirmStandingOrderPayment(
          custodianId,
          consentId,
          code as string,
          redirectUrl,
        );

        if (!paymentConfirmResponse || paymentConfirmResponse.status === PaymentStatus.Rejected) {
          throw new Error("'processPaymentConfirmation()' invalid response");
        }

        // STEP 3: Update RTP with standing order.
        const rtpUpdateResponse = await API.updateStandingOrderPayment(
          rtpState.rtpData!.paymentRequestId!,
          paymentConfirmResponse.paymentId,
          paymentConfirmResponse.payment.firstPaymentDateTime,
        );
        setConfirmationInfo({
          amount: paymentConfirmResponse.payment.firstPaymentAmount,
          bank: custodianId,
          sender:
            rtpUpdateResponse.supplier.businessContact?.name ?? rtpUpdateResponse.supplier.name,
          reference: paymentConfirmResponse.paymentId,
        });
      } else {
        //
        // STEP 2: Confirm payment.
        //
        const paymentConfirmResponse = await API.authorizePayment(
          BankId,
          consentId,
          code as string,
          redirectUrl,
        );

        if (!paymentConfirmResponse || paymentConfirmResponse.status === PaymentStatus.Rejected) {
          throw new Error("'processPaymentConfirmation()' invalid response");
        }

        setConfirmationInfo({
          amount: paymentConfirmResponse.payment.amount,
          bank: BankId,
          sender: rtpState.senderData?.businessContact?.name ?? rtpState.senderData!.name,
          reference: paymentConfirmResponse.paymentId,
        });
      }
    } catch (error: any) {
      Sentry.captureMessage(
        `Payment confirmation error,", ${JSON.stringify(error?.response?.data ?? error)}`,
      );
      setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
    }

    setLoading(false);
  };

  const processPaymarkPayment = async () => {
    const getAndSetRtpDetails = async () => {
      try {
        const rtpDetails = (await API.getRTP(rtpId as string)).data[0];
        if (
          rtpDetails.status === RTPStatus.PaymentSent ||
          rtpDetails.status === RTPStatus.PaymentReceived
        ) {
          setConfirmationInfo({
            amount: rtpDetails.amount,
            sender: rtpDetails.supplier.businessContact?.name ?? rtpDetails.supplier.name,
            reference: rtpDetails.receivable.reference,
          });
        } else {
          setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
        }
      } catch (error) {
        setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
      }
      setLoading(false);
    };

    if (queryErrorMessage) {
      setErrorMessage(queryErrorMessage as string);
      setLoading(false);
      return;
    }

    if (status === "SUCCESSFUL") {
      getAndSetRtpDetails();
      return;
    }

    setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
    setLoading(false);
  };

  const processMaverickPayment = async () => {
    try {
      setErrorMessage(undefined);

      if (!maverickPaymentId || !maverickPaymentStatus) {
        setLoading(false);
        setErrorMessage(ERROR_MESSAGES.ACCESS_DENIED);

        return;
      }

      await API.updateMaverickPaymentStatus({
        rtpId: rtpState.rtp?.id!,
        customerId: rtpState.rtpData?.payable.supplierContact.email!,
        paymentDetails: {
          paymentId: Number(maverickPaymentId),
          status: maverickPaymentStatus as string,
        },
        amount: rtpState.rtpData?.paymentDetails.amount!,
        entityDetails: rtpState.rtpData?.payable.entityDetails!,
      });

      if (maverickPaymentStatus !== "Success") {
        return setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
      }

      setConfirmationInfo({
        amount: rtpState.rtp!.amount,
        sender: rtpState.rtp!.supplier.businessContact?.name ?? rtpState.rtp!.supplier.name,
        reference: rtpState.rtp!.receivable.reference,
      });
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.MAVERICK_ERROR);
    }

    setLoading(false);
  };

  const onTryAgainMaverickUpdate = () => {
    setLoading(true);
    processMaverickPayment();
  };

  const handleFinish = () => {
    history.push("/");
    dispatch(clearRtpState());
  };

  useEffect(() => {
    if (error) {
      Sentry.captureMessage(`Payment confirmation error (query param received): "${error}" `);
      setErrorMessage(
        error === "access_denied" || error === "login_required"
          ? ERROR_MESSAGES.ACCESS_DENIED
          : ERROR_MESSAGES.CHECK_BANK,
      );
      setLoading(false);
      return;
    }

    if (config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.PAYMARK) {
      processPaymarkPayment();
      return;
    }

    if (config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MAVERICK) {
      processMaverickPayment();
      return;
    }

    processMoneyhubPayment();
  }, []);

  const renderMainContent = () => {
    if (errorMessage) {
      return (
        <Box display="flex" alignItems="center" pt="12vh">
          <Message
            type="error"
            title="Oops, something seems to have gone wrong"
            description={errorMessage}
            buttons={[
              errorMessage === ERROR_MESSAGES.MAVERICK_ERROR
                ? {
                    text: "Try again",
                    variant: "contained",
                    color: "secondary",
                    onClick: onTryAgainMaverickUpdate,
                  }
                : {
                    text: "Finish",
                    variant: "contained",
                    color: "secondary",
                    onClick: handleFinish,
                  },
            ]}
          />
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {confirmationInfo && (
          <>
            <img src={ICONS.CONFIRM_ICON} style={{ width: 60 }} alt="Success" />
            <Box mt={3}>
              <Typography component="p" variant="h6">
                Payment Successful
              </Typography>
            </Box>
            <Box mt="2.5vh" mb="3vh" textAlign="center">
              <Typography component="p" variant="subtitle1">
                To: {confirmationInfo.sender}
              </Typography>
              <Box my={1}>
                <Typography color="textSecondary" component="p" variant="h5">
                  Amount: {formatCurrency(confirmationInfo.amount)}
                </Typography>
              </Box>
              {confirmationInfo.bank ? (
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{ textTransform: "capitalize" }}>
                  {confirmationInfo.bank}
                </Typography>
              ) : null}
              {confirmationInfo.reference && (
                <Box mt={2}>
                  <Typography variant="body2" align="center">
                    Payment Reference:&nbsp;
                    <code>{confirmationInfo.reference}</code>
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
        <Box width={"100%"} mb={3}>
          <Button size="large" fullWidth variant="contained" color="primary" onClick={handleFinish}>
            Finish
          </Button>
        </Box>
        <MoneyhubLegalInfo />
      </Box>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" alignItems="center" flexDirection="column" maxWidth={560} mx="auto">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default RTPCompletePaymentScreen;
