import React, { useState, useEffect } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";

import { ICONS } from "@APP/assets";
import { PageLayout, Message, RTPSummary } from "@APP/components";
import { useAlert } from "@APP/hooks";
import { AppState, useAppSelector } from "@APP/redux";
import { history } from "@APP/navigation";
import { API } from "@APP/services";
import { BlockRequestStateEnum, getModalButtons, getModalMessage } from "./getModalContent";

interface QueryParams {
  requestId: string;
}

const DeclineRTPScreen: React.FC<RouteComponentProps> = ({ match }) => {
  const { alert, setAlertOptions } = useAlert();

  const params = match.params as QueryParams;
  const { requestId } = params;

  const rtpState = useAppSelector((state) => state.rtp);

  const [declineRequestState, setDeclineRequestState] = useState({
    error: false,
    loading: false,
    success: false,
  });

  useEffect(() => {
    if (!rtpState.rtpData) {
      history.push("/");
    }
  }, [rtpState.rtpData]);

  const showAlert = (status: BlockRequestStateEnum, componentState: AppState["rtp"]) => {
    setAlertOptions({
      title: "",
      message: {
        component: getModalMessage(status, componentState),
      },
      buttons: getModalButtons(status, {}),
    });
  };

  const handleBlockRequests = async () => {
    showAlert(BlockRequestStateEnum.loading, rtpState);
    try {
      requestId && (await API.declineRTP(requestId));
      await API.blockRTPs(
        rtpState.rtpData?.payable.debtorContact.id.externalId!,
        rtpState.rtpData?.payable.entityDetails.source,
      );

      showAlert(BlockRequestStateEnum.success, rtpState);
    } catch (err) {
      showAlert(BlockRequestStateEnum.error, rtpState);
    }
  };

  const handleDecline = async () => {
    setDeclineRequestState((prevState) => ({ ...prevState, loading: true }));
    try {
      requestId && (await API.declineRTP(requestId));
      setDeclineRequestState((prevState) => ({ ...prevState, success: true }));
    } catch (err) {
      setDeclineRequestState((prevState) => ({ ...prevState, error: true }));
    }
    setDeclineRequestState((prevState) => ({ ...prevState, loading: false }));
  };

  const showConfirmSenderBlock = () => {
    alert(
      "",
      { component: getModalMessage(null, rtpState) },
      getModalButtons(null, { handleBlockRequests }),
    );
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleFinish = () => {
    history.push("/");
  };

  const renderMainContent = () => {
    if (declineRequestState.success) {
      return (
        <Message
          type="success"
          title="Success"
          description="You have successfully declined this payment request"
          buttons={[{ variant: "contained", color: "primary", onClick: handleFinish }]}
        />
      );
    }

    if (declineRequestState.error) {
      return (
        <Message
          type="error"
          title="Oops, something seems to have gone wrong"
          description="It looks like there is a problem, please try again in a few moments"
          buttons={[{ variant: "contained", color: "secondary", onClick: handleFinish }]}
        />
      );
    }

    return (
      <>
        <img src={ICONS.DECLINE_ICON} style={{ width: "6.5vh" }} alt="Decline Request" />
        <Box mt="2.5vh" mb="3vh">
          <Typography color="textPrimary" align="center">
            Are you sure you want to decline this request for payment?
          </Typography>
        </Box>
        <RTPSummary rtpData={rtpState.rtpData} senderData={rtpState.senderData} />
        <Box display="flex" flexWrap="wrap" justifyContent="center" my="1vh" width="100%">
          <Box minWidth="200px" maxWidth="240px" m="2%">
            <Button
              size="large"
              fullWidth
              color="secondary"
              variant="contained"
              onClick={handleDecline}>
              Yes, decline
            </Button>
          </Box>
          <Box minWidth="200px" maxWidth="240px" m="2%">
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleGoBack}>
              No, go back
            </Button>
          </Box>
        </Box>
        <Box mb="2vh" textAlign="center">
          <Link
            type="button"
            component="button"
            underline="always"
            onClick={showConfirmSenderBlock}
            variant="caption"
            color="textPrimary">
            I don’t want to receive any payment requests from this sender going forward
          </Link>
        </Box>
      </>
    );
  };

  return (
    <PageLayout loading={declineRequestState.loading}>
      <Box display="flex" alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default DeclineRTPScreen;
