import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import CONFIG from "@APP/config";
import { IMAGES } from "@APP/assets";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "auto",
    maxHeight: "9vh",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "5vh",
    },
  },
}));

const Footer: React.FC = () => {
  const styles = useStyles();
  return (
    <>
      {CONFIG.ASSOCIATION.CO_OPERATIVE_BANK && (
        <Box component="footer" display="flex" justifyContent="center" pt="2vh" pb="1vh">
          <img
            className={styles.logo}
            src={IMAGES.ASSOCIATION_LOGO_DARK}
            alt="The Co-Operative Bank"
          />
        </Box>
      )}
    </>
  );
};

export default Footer;
